import React, { Component } from 'react';
import Amplify from 'aws-amplify';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import './App.css';
import ScrollToTop from './components/ScrollToTop';
import reducers from './reducers';
import { AWS_AUTH_CONFIG, AWS_STORAGE_CONFIG } from './config';
import { pages } from './navigationPages';
import { AppWrapper, SideMenu } from './components';
import { extensionRoutes } from './config/features';

class App extends Component {
  constructor(props) {
    super(props);

    Amplify.configure({
      Auth: AWS_AUTH_CONFIG,
      Storage: AWS_STORAGE_CONFIG,
    });
  }

  renderExtensionRoutes() {
    if (!extensionRoutes || extensionRoutes.length === 0) return null;
    return extensionRoutes.map((r) => <Route key={r.path} path={r.path} exact={r.exact} component={pages[r.component]} />);
  }

  render() {
    const store = createStore(reducers, applyMiddleware(ReduxThunk));

    return (
      <Provider store={store}>
        <Router onUpdate={() => window.scrollTo(0, 0)}>
          <AppWrapper>
            <ScrollToTop />
            <SideMenu />
            {this.renderExtensionRoutes()}
            <Route path="/" exact component={pages.Splash} />
            <Route path="/login" exact component={pages.Login} />
            <Route path="/logout" exact component={pages.Logout} />
            <Route path="/mastermenu" exact component={pages.Dashboard} />

            <Route path="/profile" exact component={pages.Profile} />

            <Route path="/android" exact component={pages.AndroidDownload} />
            <Route path="/testflight" exact component={pages.TestflightDownload} />

            {/* New Section Hubs */}
            {/* Events */}
            <Route path="/events" component={pages.EventsHub} />
            <Route path="/events/event" exact component={pages.AddEvent} />
            <Route path="/events/event/:eventId" exact component={pages.AddEvent} />
            <Route path="/events/analytics/:infoId" exact component={pages.EventAnalytics} />
            <Route path="/events/eventsubmission/:updateId" exact component={pages.EventSubmission} />

            {/* Facility */}
            <Route path="/facilities" component={pages.FacilityHub} />
            <Route path="/facilities/facility" exact component={pages.AddFacility} />
            <Route path="/facilities/facility/:facilityId" exact component={pages.AddFacility} />
            <Route path="/facilities/analytics/:infoId" exact component={pages.FacilityAnalytics} />

            {/* Services */}
            <Route path="/services" component={pages.ServicesHub} />
            <Route path="/services/service" exact component={pages.AddService} />
            <Route path="/services/service/:infoId" exact component={pages.AddService} />
            <Route path="/services/analytics/:infoId" exact component={pages.ServiceAnalytics} />
            {/* Sponsors */}
            <Route path="/sponsors" component={pages.SponsorsHub} />
            <Route path="/sponsors/sponsor" exact component={pages.AddService} />
            <Route path="/sponsors/sponsor/:infoId" exact component={pages.AddService} />

            {/* Alerts */}
            <Route path="/alerts" component={pages.AlertsHub} />
            <Route path="/alerts/addalert" exact component={pages.AddAlert} />
            <Route path="/alerts/alert/:infoId" exact component={pages.Alert} />

            {/* Polls */}
            <Route path="/polls" component={pages.PollsHub} />
            <Route path="/polls/poll" exact component={pages.AddPoll} />
            <Route path="/polls/poll/:pollId" exact component={pages.AddPoll} />
            <Route path="/polls/results/:pollId" exact component={pages.PollResults} />
            <Route path="/polls/livepoll" component={() => <pages.AddPoll type="live" />} />

            <Route path="/master" exact component={pages.MasterMenu} />
            <Route path="/master/appstore" exact component={pages.AppStoreAssets} />
            <Route path="/master/appassets" exact component={pages.AppAssets} />
            <Route path="/master/loginpicker" exact component={pages.LoginCustomiser} />

            {/* Offers */}
            <Route path="/offers" component={pages.OffersHub} />
            <Route path="/offers/offer" exact component={pages.AddOffer} />
            <Route path="/offers/offer/:infoId" exact component={pages.AddOffer} />

            {/* Support */}
            <Route path="/support" component={pages.SupportHub} />
            <Route path="/support/ticket" exact component={pages.AddTicket} />
            <Route path="/support/ticket/:ticketId" exact component={pages.Ticket} />
            <Route path="/roadmap" component={() => <pages.Upvoty embedType="roadmap" startPage="roadmap" />} />
            <Route path="/changelog" component={() => <pages.Upvoty embedType="changelog" startPage="changelog" />} />
            <Route path="/improvements" component={() => <pages.Upvoty embedType="board" startPage="board" />} />

            {/* Sign Ins */}
            <Route path="/signinHub" component={pages.SignInHub} />
            <Route path="/signinHub/key" exact component={pages.AddKey} />
            <Route path="/signinHub/key/:infoId" exact component={pages.AddKey} />
            <Route path="/signinHub/keyhistory/:infoId" exact component={pages.KeyHistory} />
            <Route path="/signin" component={pages.SignInForm} />
            <Route path="/signinpreview" component={pages.SignInFormPreview} />

            {/* Food */}
            <Route path="/food" component={pages.FoodHub} />
            <Route path="/food/dish" exact component={pages.AddDish} />
            <Route path="/food/dish/:dishId" exact component={pages.AddDish} />

            {/* Users */}
            <Route path="/usersHub" component={pages.UsersHub} />
            <Route path="/usersHub/adduser" exact component={pages.AddUser} />
            <Route path="/usersHub/addkiosk" exact component={pages.AddKiosk} />
            <Route path="/usersHub/bulkadduser" exact component={pages.BulkImport} />
            <Route path="/usersHub/bulkuploads" exact component={pages.BulkUploads} />
            <Route path="/usersHub/user/:userId" exact component={pages.User} />
            <Route path="/usersHub/kiosk/:userId" exact component={pages.Kiosk} />
            <Route path="/usersHub/signupform" exact component={pages.SignUpQuestions} />
            {/* <Route path="/usersHub/linkusers" exact component={pages.LinkUsers} />
            <Route path="/usersHub/linkusers/:userId" exact component={pages.LinkUsers} /> */}

            {/* Information */}
            <Route path="/info" component={pages.InfoHub} />
            {/* ----> Important Contacts */}
            <Route path="/info/importantcontact" exact component={pages.AddImportantContact} />
            <Route path="/info/importantcontact/:infoId" exact component={pages.AddImportantContact} />
            {/* ----> Info Pages */}
            <Route path="/info/infopage" exact component={pages.AddInfoPage} />
            <Route path="/info/infopage/:infoId" exact component={pages.AddInfoPage} />
            {/* ----> Welcome Pages */}
            <Route path="/welcome" exact component={pages.WelcomePagesPage} />
            <Route path="/info/welcome" exact component={pages.AddWelcomePage} />
            <Route path="/info/welcome/:infoId" exact component={pages.AddWelcomePage} />
            {/* ----> Maps */}
            <Route path="/info/map" exact component={pages.AddMap} />
            <Route path="/info/map/:infoId" exact component={pages.AddMap} />

            {/* Settings */}
            <Route path="/settings" component={pages.SettingsHub} />
            <Route path="/permissions" component={pages.PermissionsPage} />
            <Route path="/inviteCodes" component={pages.InviteCodesPage} />
            <Route path="/inviteCodes/code" component={pages.AddInviteCode} />
            <Route path="/inviteCodes/code/:code" component={pages.AddInviteCode} />
            <Route path="/featurepicker" exact component={pages.FeaturePicker} />
            <Route path="/newsite" exact component={() => <pages.FeaturePicker isNew />} />
            <Route path="/newsitefromtemplate" exact component={() => <pages.FeaturePicker isNew fromTemplate />} />
            <Route path="/signup/features" exact component={() => <pages.FeaturePicker signup fromTemplate />} />
            <Route path="/signup/complete" exact component={pages.SiteSignUpComplete} />
            <Route path="/signup" exact component={pages.SiteSignUp} />
            <Route path="/interface" exact component={() => <pages.FeaturePicker isInterface isNew />} />
            <Route
              path="/interface/:interfaceId"
              exact
              component={(props) => <pages.FeaturePicker isInterface isNew key={props.match.params.interfaceId} />}
            />

            {/* ----> TV */}
            <Route path="/tvpicker" exact component={pages.TVPicker} />
            <Route path="/tvpicker/:interfaceId" exact component={(props) => <pages.TVPicker key={props.match.params.interfaceId} />} />
            <Route path="/tv" exact component={pages.TV} />
            <Route path="/activatetv" exact component={pages.ActivateTV} />

            {/* Payments */}
            <Route path="/commBank/addCard" exact component={pages.AddCardCommBank} />
            <Route path="/stripe/addCard" exact component={pages.AddCardStripe} />
            <Route path="/billing" exact component={pages.Billing} />
            <Route path="/payment" component={pages.Payment} />

            {/* Image Library */}
            <Route path="/imageLibrary" component={pages.ImageLibraryHub} />

            {/* Forms */}
            <Route path="/signinHub/form" exact component={pages.AddForm} />
            <Route path="/signinHub/form/:formId" exact component={pages.AddForm} />

            {/* Comments */}
            <Route path="/comments" component={pages.CommentsDashboard} />
            <Route path="/comments/thread/:threadId" exact component={pages.Thread} />

            {/* Automation */}
            <Route path="/automationHub" component={pages.AutomationHub} />
            <Route path="/automationHub/aws_account" exact component={pages.AWSAccount} />
            <Route path="/automationHub/aws_setup" exact component={pages.AWSSetup} />
            <Route path="/automationHub/repository" exact component={pages.Repository} />
            <Route path="/automationHub/app_ios" exact component={pages.AppSetupiOS} />
            <Route path="/automationHub/app_android" exact component={pages.AppSetupAndroid} />
            <Route path="/automationHub/deployment" exact component={pages.Deployment} />

            {/* Embeds */}
            <Route path="/e/poll/:pollId" exact component={pages.PollEmbed} />
          </AppWrapper>
        </Router>
      </Provider>
    );
  }
}

export default App;
