import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { servicesUpdate, addRecentlyCreated } from '../../actions';
import {
  Button,
  DropdownInput,
  GenericInput,
  ImageInput,
  OverlayPage,
  OverlayPageContents,
  OverlayPageSection,
  OverlayPageBottomButtons,
  TimePicker,
  RadioButton,
  P60Icon,
  SuccessPopup,
  TextFormatPopup,
  OptionsSection,
  SVGIcon,
  Tag,
  AudienceSelector,
} from '../../components';
import { serviceActions } from '../../webapi';
import { safeReadParams, isEmail } from '../../helper';
import bookingTypes from '../../json/serviceBookingTypes.json';
import categories from '../../json/eventCategories.json';
import { Text } from '../../components/text';
import { COLOUR_DUSK } from '../../js';

class AddService extends Component {
  initialState = {
    serviceType: window.location.pathname.indexOf('/sponsors') === 0 ? 'SPONSOR' : 'SERVICE',
    Days: {
      Mon: { Key: 'Mon', Title: 'Mon', Value: 1 },
      Tue: { Key: 'Tue', Title: 'Tue', Value: 2 },
      Wed: { Key: 'Wed', Title: 'Wed', Value: 3 },
      Thu: { Key: 'Thu', Title: 'Thu', Value: 4 },
      Fri: { Key: 'Fri', Title: 'Fri', Value: 5 },
      Sat: { Key: 'Sat', Title: 'Sat', Value: 6 },
      Sun: { Key: 'Sun', Title: 'Sun', Value: 7 },
    },

    infoId: safeReadParams(this.props, 'infoId'),
    loading: true,

    Deleted: false,

    Title: '',
    Description: '',
    Location: '',
    Email: '',
    Price: '',

    ContactType: 'single',
    Contact: '',
    Phone: '',

    Contacts: [],

    Sponsored: window.location.pathname.indexOf('/sponsors') === 0,
    Internal: window.location.pathname.indexOf('/services') === 0,

    Link: '',
    LinkType: 'NoRequest',
    // LinkType: 'EmailRequest',

    shouldNotify: false,

    showWarnings: false,
    success: false,
    submitting: false,

    Times: [],

    links: [],

    isAudienceValid: true,
    audienceType: '',
    audienceTypeSelection: [],
  };

  state = { ...this.initialState };

  UNSAFE_componentWillMount() {
    this.onNewProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.onNewProps(nextProps);
  }

  componentDidMount() {
    this.getServices();
    if (!_.isUndefined(this.state.infoId) && !_.isEmpty(this.state.infoId)) {
      this.getService();
    } else {
      this.setState({ loading: false });
    }
    this.props.addRecentlyCreated(this.state.Sponsored ? 'sponsors' : 'services');
  }

  selectOption = (o) => {
    this.setState({
      selectedOption: o,
    });
  };

  getService() {
    serviceActions
      .getService(this.state.infoId)
      .then((res) => {
        this.parseUpdate(res.data);
      })
      .catch((error) => {});
  }

  getServices() {
    serviceActions
      .getServices(this.props.auth.site)
      .then((res) => {
        if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
          this.props.servicesLoaded(res.data);
        }
      })
      .catch((error) => {});
  }

  parseUpdate(update) {
    const newState = {
      Title: update.Title || '',
      Description: update.Description || '',
      Location: update.Location || '',
      Email: update.Email || '',
      Link: update.Link || '',
      Price: update.Price || '',
      Category: update.Category || '',
      ContactType: update.ContactType || 'single',
      Contact: update.Contact || '',
      Contacts: update.Contacts || [],
      Phone: update.Phone || '',
      Deleted: update.Deleted || false,
      Sponsored: update.Sponsored || false,
      loading: false,
      audienceType: update.AudienceType || '',
      audienceTypeSelection: update.AudienceTypeSelection || [],
    };

    if (!_.isEmpty(update.Category)) {
      const tags = this.selectCategory(update.Category);
      if (tags && !_.isEmpty(update.Tags)) {
        _.values(tags).forEach((tag) => {
          tag.Active = update.Tags.indexOf(tag.Key) > -1;
        });
        newState.Tags = tags;
      }
    }
    if (!_.isUndefined(update.Times) && !_.isEmpty(update.Times)) {
      newState.Times = update.Times;
    }
    if (_.isUndefined(update.Internal)) {
      newState.Internal = true;
    } else {
      newState.Internal = update.Internal;
    }
    if (!_.isEmpty(update.Links)) {
      newState.links = update.Links;
    }
    if (_.isUndefined(update.LinkType) && _.isEmpty(update.LinkType)) {
      newState.LinkType = 'EmailRequest';
    } else {
      newState.LinkType = update.LinkType;
    }
    this.checkSetLogo(update.Logo);
    this.checkSetImage(update.Image);
    this.checkSetContactImages(newState.Contacts);
    this.setState(newState);
  }

  selectBookingType(tag) {
    if (!bookingTypes[tag]) {
      return null;
    }
    // const newBooking = this.state.bookingData;
    // newBooking.type = tag;
    this.setState({
      LinkType: tag,
    });
  }

  getSelectedBookingType() {
    if (bookingTypes[this.state.LinkType]) {
      return bookingTypes[this.state.LinkType];
    }
    return {
      Title: 'Select',
    };
  }

  getSelectedRepDay(repetition) {
    if (this.state.Days[repetition]) {
      return this.state.Days[repetition];
    }
    return {
      Title: 'Select day',
    };
  }

  validateRepDay(rep) {
    if (this.state.Days[rep.Day] != null) {
      return true;
    }
    return false;
  }

  toggleTextFormat(isOpen) {
    this.setState({
      textFormatOpen: isOpen,
    });
  }

  selectRepDay(id, tag) {
    var stateChange = {
      Times: this.state.Times,
    };
    const index = _.findIndex(stateChange.Times, (rep) => {
      return rep.Id === id;
    });
    stateChange.Times[index].Day = tag;
    stateChange.Times[index].Value = this.state.Days[tag].Value;
    this.setState(stateChange);
  }

  handleRepetitionTimeChange(id, time) {
    var stateChange = {
      Times: this.state.Times,
    };
    const index = _.findIndex(stateChange.Times, (rep) => {
      return rep.Id === id;
    });
    stateChange.Times[index].StartTime = time;
    this.setState(stateChange);
  }

  handleRepetitionEndTimeChange(id, time) {
    var stateChange = {
      Times: this.state.Times,
    };
    const index = _.findIndex(stateChange.Times, (rep) => {
      return rep.Id === id;
    });
    stateChange.Times[index].EndTime = time;
    this.setState(stateChange);
  }

  checkSetProfPic(id, url) {
    if (this.refs[`imageInput_${id.toString()}`]) {
      this.refs[`imageInput_${id.toString()}`].getWrappedInstance().setValue(url);
    } else {
      setTimeout(() => {
        this.checkSetProfPic(id, url);
      }, 100);
    }
  }

  checkSetContactImages(contacts) {
    if (!_.isUndefined(contacts) && !_.isEmpty(contacts)) {
      setTimeout(() => {
        contacts.forEach((element) => {
          this.checkSetProfPic(element.Id, element.Image);
        });
      }, 300);
    }
  }

  checkSetLogo(url) {
    if (this.refs.logoInput) {
      this.setState({
        logoInput: url,
      });
      this.refs.logoInput.getWrappedInstance().setValue(url);
    } else {
      setTimeout(() => {
        this.checkSetLogo(url);
      }, 100);
    }
  }

  checkSetImage(url) {
    if (this.refs.imageInput) {
      this.setState({
        imageInput: url,
      });
      this.refs.imageInput.getWrappedInstance().setValue(url);
    } else {
      setTimeout(() => {
        this.checkSetImage(url);
      }, 100);
    }
  }

  onLogoUpdated = (url) => {
    this.setState({
      logoInput: url,
    });
  };

  onImageUpdated = (url) => {
    this.setState({
      imageInput: url,
    });
  };

  inputsDisabled() {
    if (this.state.submitting) {
      return true;
    }
    return false;
  }

  onNewProps(props) {
    const infoId = safeReadParams(this.props, 'infoId');
    this.setState({ infoId });
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  selectCategory(tag) {
    if (!categories[tag]) {
      return null;
    }
    const tags = categories[tag].Sub;
    this.setState({
      Category: tag,
      selectableTags: tags,
    });
    return tags;
  }

  toggleTag(tag) {
    if (this.inputsDisabled()) {
      return;
    }
    const newTags = { ...this.state.selectableTags };
    newTags[tag].Active = !newTags[tag].Active;
    this.setState({
      selectableTags: newTags,
    });
  }

  setContacts() {
    const Contacts = [];
    if (!_.isEmpty(this.state.Contacts)) {
      this.state.Contacts.forEach((e) => {
        if (e != null && !e.Removed && !_.isEmpty(e.Phone) && !_.isEmpty(e.Name)) {
          Contacts.push({
            Id: e.Id,
            Name: e.Name,
            Phone: e.Phone,
            Image: this.refs[`imageInput_${e.Id.toString()}`].getWrappedInstance().getValue(),
          });
        }
      });
    }
    return Contacts;
  }

  setTags(input) {
    input.Tags = _.filter(_.values(this.state.selectableTags), (tag) => {
      return tag.Active;
    }).map((tag) => {
      return tag.Key;
    });
  }

  validateCompulsoryText() {
    if (_.isEmpty(this.state.Title)) {
      return false;
    }
    if (this.state.Internal && _.isEmpty(this.state.Description)) {
      return false;
    }
    if (
      (this.state.Internal && this.state.LinkType === 'EmailRequest' && this.state.Email == null) ||
      (this.state.Internal && this.state.LinkType === 'EmailRequest' && !isEmail(this.state.Email))
    ) {
      return false;
    }
    if (!this.state.Internal || (this.state.Internal && this.state.LinkType === 'External')) {
      if (!this.isValidLink()) {
        return false;
      }
    }
    return true;
  }

  validateLogo() {
    return this.state.logoInput && this.state.logoInput.match(/\.(jpeg|jpg|gif|png|ashx)/) != null;
  }

  validateImage() {
    return this.state.imageInput && this.state.imageInput.match(/\.(jpeg|jpg|gif|png|ashx)/) != null;
  }

  validateForm() {
    if (!this.validateCompulsoryText()) {
      return false;
    }
    if (this.state.submitting) {
      return false;
    }
    if (!this.state.Internal && !this.validateLogo()) {
      return false;
    }
    if (this.state.Internal && !this.validateImage()) {
      return false;
    }
    if (!this.state.isAudienceValid) {
      return false;
    }
    return true;
  }

  setDays(obj) {
    let source = this.state.Times;
    source = _.filter(source, (ev) => {
      return !ev.Removed;
    });
    source = _.filter(source, (ev) => {
      return ev.Day != null;
    });
    obj.Times = source;
  }

  handleSubmit() {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({ submitting: true, showWarnings: false });
    const obj = {
      RowId: this.state.infoId,
      Title: this.state.Title,
      Description: this.state.Description,
      Location: this.state.Location,
      Email: this.state.Email,
      Price: this.state.Price,
      Site: this.props.auth.site,
      Category: this.state.Category,
      Deleted: this.state.Deleted,
      Sponsored: this.state.Sponsored || !this.state.Internal,
      ContactType: this.state.ContactType,
      Contact: '',
      Phone: this.state.Phone,
      Internal: this.state.Internal,
      LinkType: this.state.LinkType,
      Link: this.state.Link,
      Notification: _.includes(this.props.auth.hidden, 'serviceNotifications') ? false : this.state.shouldNotify,
      Links: [...this.state.links],
      AudienceType: this.state.audienceType,
      AudienceTypeSelection: this.state.audienceTypeSelection,
    };
    this.setTags(obj);
    this.setDays(obj);

    if (this.state.Sponsored || !this.state.Internal) {
      obj.Logo = this.refs.logoInput.getWrappedInstance().getValue();
      obj.LogoThumbnail = this.refs.logoInput.getWrappedInstance().getValue();
    }

    if (this.state.Internal) {
      obj.Image = this.refs.imageInput.getWrappedInstance().getValue();
      obj.Thumbnail = this.refs.imageInput.getWrappedInstance().getValue();
    }

    if (obj.ContactType === 'multi') {
      obj.Contacts = this.setContacts(obj);
    } else {
      obj.Contact = this.state.Contact;
    }

    serviceActions
      .addService(this.props.auth.site, obj)
      .then((res) => {
        this.setState({
          success: true,
          submitting: false,
        });
        this.props.servicesUpdate(this.props.auth.site);
      })
      .catch((res) => {
        this.setState({ submitting: false });
        alert('Something went wrong with the request. Please try again.');
      });
  }

  getSelectedCategory() {
    if (categories[this.state.Category]) {
      return categories[this.state.Category];
    }
    return {
      Title: 'Category',
    };
  }

  validateCategory() {
    if (categories[this.state.Category]) {
      return true;
    }
    return false;
  }

  renderSubCategories() {
    if (_.isEmpty(this.state.Category) || !categories[this.state.Category] || _.isEmpty(this.state.selectableTags)) {
      return null;
    }
    return (
      <div className="flex-1">
        <div className="fieldLabel marginBottom-8">Tag/s</div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {_.values(this.state.selectableTags).map((tag) => {
            return (
              <Tag
                key={tag.Key}
                className="marginTop-8 marginRight-8"
                text={tag.Title}
                onClick={this.toggleTag.bind(this, tag.Key)}
                leftIcon={tag.Active ? 'check' : null}
              />
            );
          })}
        </div>
      </div>
    );
  }

  isValidLink() {
    const regexp = /(http:\/\/|https:\/\/)/;
    if (regexp.test(this.state.Link)) {
      return true;
    }
    return false;
  }

  addLink = () => {
    const newLinks = [...this.state.links];
    newLinks.push({
      Text: '',
      Url: '',
    });
    this.setState({ links: newLinks });
  };

  removeLink = (index) => {
    const newLinks = [...this.state.links];
    newLinks.splice(index, 1);
    this.setState({ links: newLinks });
  };

  handleLinkChange = (index, key, value) => {
    const newLinks = [...this.state.links];
    const newLink = { ...newLinks[index] };
    newLink[key] = value;
    newLinks[index] = newLink;
    this.setState({
      links: newLinks,
    });
  };

  handleRepetitionChange(Id, column, value) {
    var stateChange = {
      Contacts: this.state.Contacts,
    };
    const index = _.findIndex(stateChange.Contacts, (rep) => {
      return rep.Id === Id;
    });
    stateChange.Contacts[index][column] = value;
    this.setState(stateChange);
  }

  removeUser(rep) {
    rep.Removed = true;

    this.setState({ Contacts: [...this.state.Contacts] });
  }

  addNewUser() {
    const Contacts = [...this.state.Contacts];
    const id = _.isEmpty(this.state.Contacts) ? 0 : _.maxBy(this.state.Contacts, 'Id').Id + 1;

    Contacts.push({
      Id: id,
      Name: '',
      Phone: '',
      Image: '',
    });
    this.setState({ Contacts });
  }

  onChangeAudience = (audienceType, audienceTypeSelection, isAudienceValid) => {
    this.setState({ audienceType, audienceTypeSelection, isAudienceValid });
  };

  renderContacts() {
    if (this.state.ContactType === 'multi') {
      let source = this.state.Contacts;
      source = _.filter(source, (ev) => {
        return !ev.Removed;
      });
      return (
        <div>
          {_.map(source, (repetition, index) => {
            if (repetition == null) {
              return null;
            }
            return (
              <div key={repetition.Id} className="genericBoxShadow" style={styles.contactContainer}>
                <Text type="formLabel" className="marginBottom-8">
                  Contact Photo (optional)
                </Text>
                <ImageInput
                  ref={`imageInput_${repetition.Id.toString()}`}
                  containerStyle={{ marginBottom: 16, width: 260, height: 180 }}
                  style={{ width: 260, height: 180 }}
                  noMenu
                />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <GenericInput
                    id="Contact"
                    type="text"
                    label="Name"
                    placeholder="Name"
                    value={repetition.Name}
                    onChange={(e) => this.handleRepetitionChange(repetition.Id, 'Name', e.target.value)}
                    style={{ width: '100%', maxWidth: 325, marginRight: 24 }}
                  />
                  <GenericInput
                    id="Phone"
                    type="text"
                    label="Contact number"
                    placeholder="Contact number"
                    value={repetition.Phone}
                    onChange={(e) => this.handleRepetitionChange(repetition.Id, 'Phone', e.target.value)}
                    style={{ width: '100%', marginLeft: 24 }}
                  />
                </div>
                <Button inline buttonType="outlined" onClick={() => this.removeUser(repetition)} isActive buttonStyle={{ marginTop: 8 }}>
                  Remove Contact
                </Button>
              </div>
            );
          })}
          <Button inline buttonType="secondary" onClick={() => this.addNewUser()} isActive buttonStyle={{ marginTop: 8 }}>
            Add contact
          </Button>
        </div>
      );
    }
  }

  renderBookingOptions() {
    return (
      <div className="optionsContent_bottom">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: 250, maxWidth: '50%', marginRight: 24 }}>
            <DropdownInput
              id="bookingType"
              label="Booking Type"
              placeholder="Booking Type"
              value={this.getSelectedBookingType().Title}
              options={bookingTypes}
              onSelect={this.selectBookingType.bind(this)}
              disabled={this.inputsDisabled()}
            />
          </div>
          {this.state.LinkType === 'EmailRequest' && (
            <GenericInput
              id="Email"
              type="text"
              label="Booking email"
              placeholder="Booking email"
              showError={() => {
                return this.state.showWarnings && !isEmail(this.state.Email);
              }}
              value={this.state.Email}
              onChange={(e) => this.handleChange(e)}
              isRequired
              isValid={() => {
                return isEmail(this.state.Email);
              }}
              style={{ flex: 1, marginLeft: 24 }}
              help={'Email that booking enquiries are sent to.'}
              alwaysShowLabel
            />
          )}
          {this.state.LinkType === 'External' && (
            <GenericInput
              id="Link"
              type="text"
              label="External Link"
              placeholder="https//..."
              showError={() => {
                return this.state.showWarnings && !this.isValidLink(this.state.Link);
              }}
              value={this.state.Link}
              onChange={(e) => this.handleChange(e)}
              isRequired
              isValid={() => {
                return this.isValidLink(this.state.Link);
              }}
              style={{ flex: 1, marginLeft: 24 }}
              help={'External webpage users are taken to.'}
              alwaysShowLabel
            />
          )}
        </div>
      </div>
    );
  }

  renderAudience() {
    const { auth } = this.props;
    const { audienceType, audienceTypeSelection } = this.state;

    return (
      <div className="optionsContent_bottom">
        <AudienceSelector
          onChange={this.onChangeAudience}
          auth={auth}
          custom
          audienceType={audienceType}
          audienceTypeSelection={audienceTypeSelection}
          disallowSingleUsers
          disallowUserType
        />
      </div>
    );
  }

  renderPublishOptions() {
    return <div className="optionsContent_bottom optionsContent_bottom-noTopPadding">{this.renderNotify()}</div>;
  }

  renderOpeningHours() {
    return (
      <div className="optionsContent_bottom">
        {this.renderTimes()}
        <Button buttonType="outlined" isActive onClick={() => this.addRepetition()}>
          Add day
        </Button>
      </div>
    );
  }

  renderAdditionalInfo() {
    return (
      <div className="optionsContent_bottom">
        <GenericInput
          id="Location"
          type="text"
          label="Location"
          placeholder="Location"
          value={this.state.Location}
          onChange={(e) => this.handleChange(e)}
          alwaysShowLabel
        />
        <GenericInput
          id="Price"
          type="text"
          label="Price"
          placeholder="Price range"
          value={this.state.Price}
          onChange={(e) => this.handleChange(e)}
          style={{ maxWidth: 325 }}
          alwaysShowLabel
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '100%', maxWidth: 300, marginRight: 16 }}>
            <DropdownInput
              id="Category"
              label="Category"
              placeholder="Category"
              ignoreValue="Category"
              value={this.getSelectedCategory().Title}
              options={categories}
              onSelect={this.selectCategory.bind(this)}
              disabled={this.inputsDisabled()}
            />
            {/* secondary tags */}
          </div>
          {this.renderSubCategories()}
        </div>
      </div>
    );
  }

  renderContactOptions() {
    return (
      <div className="optionsContent_bottom">
        <RadioButton
          isActive={this.state.ContactType}
          rowStyle={{ marginBottom: 16 }}
          options={[
            {
              Label: 'Single Contact',
              Value: 'single',
              onChange: () => this.setState({ ContactType: 'single' }),
            },
            {
              Label: 'Multiple Contacts',
              Value: 'multi',
              onChange: () => this.setState({ ContactType: 'multi' }),
            },
          ]}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {this.state.ContactType === 'single' && (
            <GenericInput
              id="Contact"
              type="text"
              label="Name"
              placeholder="Name"
              value={this.state.Contact}
              onChange={(e) => this.handleChange(e)}
              style={{ width: '100%', maxWidth: 325, marginRight: 24 }}
              alwaysShowLabel
            />
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <GenericInput
            id="EmailInput"
            type="text"
            label="Contact Email"
            placeholder="Contact Email"
            value={this.state.Email}
            onChange={(e) => {
              this.setState({ Email: e.target.value });
            }}
            style={{ width: '100%', marginRight: 12 }}
            alwaysShowLabel
          />
          <GenericInput
            id="Phone"
            type="text"
            label="Service Contact number"
            placeholder="Service Contact number"
            value={this.state.Phone}
            onChange={(e) => this.handleChange(e)}
            style={{ width: '100%', marginLeft: 12 }}
            alwaysShowLabel
          />
        </div>
        {this.renderContacts()}
      </div>
    );
  }

  renderSponsorOptions() {
    return (
      <div className="optionsContent_bottom">
        {this.state.Internal && (
          <div className="marginBottom-16">
            <RadioButton
              label="Is this a sponsored service?"
              isActive={this.state.Sponsored}
              options={[
                { Label: 'Yes', Value: true, onChange: () => this.setState({ Sponsored: true }) },
                { Label: 'No', Value: false, onChange: () => this.setState({ Sponsored: false }) },
              ]}
            />
            <div className="genericInput-help" style={{ marginTop: 4 }}>
              A Sponsored Service will appear at the top of the services list in the app and can be attached to news posts like a regular
              sponsor.
            </div>
          </div>
        )}

        {this.state.Sponsored && (
          <div>
            <Text type="formLabel" className="marginBottom-16">
              Logo Image
            </Text>
            <ImageInput
              ref="logoInput"
              label="LOGO IMAGE"
              isRequired
              showError={() => {
                return this.state.showWarnings && !this.validateLogo();
              }}
              disableRemove={this.inputsDisabled()}
              refreshCallback={this.onLogoUpdated}
              simpleStyle
              noMenu
              noDownload
            />
          </div>
        )}
      </div>
    );
  }

  renderAttachments() {
    return (
      <div className="optionsContent_bottom">
        <Text type="formTitleSmall" className="marginBottom-16">
          Links
        </Text>
        <div>
          {this.state.links.map((link, index) => {
            return (
              <div key={index} className="pdfAttachmentInput">
                <FontAwesome className="pdfAttachmentInput_icon paddingTop-8 marginRight-16" name="link" />
                <GenericInput
                  id={`newsLink${index}`}
                  label="Title"
                  type="text"
                  placeholder="Enter Title"
                  value={link.Text}
                  onChange={(e) => this.handleLinkChange(index, 'Text', e.target.value)}
                  disabled={this.inputsDisabled()}
                  alwaysShowLabel
                  style={{ margin: 0, marginRight: 16, flex: 1 }}
                />
                <GenericInput
                  id={`newsLink${index}`}
                  label="URL"
                  type="text"
                  placeholder="https://"
                  value={link.Url}
                  onChange={(e) => this.handleLinkChange(index, 'Url', e.target.value)}
                  disabled={this.inputsDisabled()}
                  alwaysShowLabel
                  style={{ margin: 0, flex: 1 }}
                />
                <P60Icon
                  className="removeoption pdfAttachmentInput_remove"
                  icon="remove-circle"
                  onClick={() => {
                    this.removeLink(index);
                  }}
                />
              </div>
            );
          })}
          <div className="iconTextButton marginTop-16" onClick={this.addLink}>
            <FontAwesome className="iconTextButton_icon" name="plus" />
            <p className="iconTextButton_text">Add Link</p>
          </div>
        </div>
      </div>
    );
  }

  renderSelectedOption(selectedOption) {
    switch (selectedOption) {
      case 'details':
        return this.renderAdditionalInfo();
      case 'bookingOption':
        return this.renderBookingOptions();
      case 'audience':
        return this.renderAudience();
      case 'publishOptions':
        return this.renderPublishOptions();
      case 'openingHours':
        return this.renderOpeningHours();
      case 'contacts':
        return this.renderContactOptions();
      case 'sponsorOptions':
        return this.renderSponsorOptions();
      case 'attachments':
        return this.renderAttachments();
      default:
        return null;
    }
  }

  renderOptionsSection() {
    let options = [
      {
        key: 'details',
        icon: 'info',
        text: 'Details',
      },
      {
        key: 'audience',
        icon: 'audience',
        text: 'Audience',
      },
      {
        key: 'publishOptions',
        icon: 'bell',
        text: 'Publish Options',
      },
      {
        key: 'bookingOption',
        icon: 'pencil-o',
        text: 'Booking Option',
      },
      {
        key: 'openingHours',
        icon: 'clock',
        text: 'Opening Hours',
      },
      {
        key: 'contacts',
        icon: 'user-card',
        text: 'Contact Details',
      },
      {
        key: 'sponsorOptions',
        icon: 'star',
        text: 'Sponsor',
      },
      {
        key: 'attachments',
        icon: 'attachments',
        text: 'Attachments',
      },
    ];
    if (!this.state.Internal) {
      //sponsor
      options = [
        {
          key: 'sponsorOptions',
          icon: 'star',
          text: 'Sponsor',
        },
      ];
    }
    if (this.state.infoId || _.includes(this.props.auth.hidden, 'serviceNotifications')) {
      options = _.filter(options, (o) => {
        return o.key !== 'publishOptions';
      });
    }
    if (_.isEmpty(options)) {
      return null;
    }

    const selectedOption = this.state.selectedOption || options[0].key;

    return (
      <OptionsSection options={options} selected={selectedOption} selectOption={this.selectOption}>
        {this.renderSelectedOption(selectedOption)}
      </OptionsSection>
    );
  }

  renderForm() {
    if (this.state.success) {
      return null;
    }
    return (
      <div>
        <div className="padding-60 paddingVertical-40 bottomDivideBorder">
          <Text type="formTitleLarge" className="marginBottom-24">
            {this.state.infoId == null ? 'New' : 'Edit'} {window.location.pathname.indexOf('/sponsors') === 0 ? 'Sponsor' : 'Service'}
          </Text>

          <div className="flex flex-reverse">
            {this.state.Internal && (
              <ImageInput
                ref="imageInput"
                isRequired
                showError={() => {
                  return this.state.showWarnings && !this.validateImage();
                }}
                disableRemove={this.inputsDisabled()}
                refreshCallback={this.onImageUpdated}
                containerStyle={{ marginLeft: 40 }}
              />
            )}
            <div className="flex-1">
              {/* title */}
              <GenericInput
                id="Title"
                type="text"
                label="Title"
                placeholder="Enter service title"
                showError={() => {
                  return this.state.showWarnings && _.isEmpty(this.state.Title);
                }}
                value={this.state.Title}
                onChange={(e) => this.handleChange(e)}
                isRequired
                isValid={() => {
                  return !_.isEmpty(this.state.Title);
                }}
                alwaysShowLabel
              />

              {/* External Link */}
              {!this.state.Internal && (
                <GenericInput
                  id="Link"
                  type="text"
                  label="External Link"
                  placeholder="https//..."
                  showError={() => {
                    return this.state.showWarnings && !this.isValidLink(this.state.Link);
                  }}
                  value={this.state.Link}
                  onChange={(e) => this.handleChange(e)}
                  isRequired
                  isValid={() => {
                    return this.isValidLink(this.state.Link);
                  }}
                  help={'External webpage users are taken to.'}
                  alwaysShowLabel
                />
              )}

              {this.state.Internal && (
                <GenericInput
                  id="Description"
                  label="Description"
                  type="textarea"
                  componentClass="textarea"
                  placeholder="Description"
                  value={this.state.Description}
                  onChange={(e) => this.handleChange(e)}
                  inputStyle={{
                    height: 120,
                  }}
                  isRequired
                  showError={() => {
                    return this.state.showWarnings && _.isEmpty(this.state.Description);
                  }}
                  isValid={() => {
                    return !_.isEmpty(this.state.Description);
                  }}
                  help={
                    <a
                      onClick={() => {
                        this.toggleTextFormat(true);
                      }}
                      className="pointer"
                    >
                      Text formatting
                    </a>
                  }
                  alwaysShowLabel
                />
              )}
            </div>
          </div>
        </div>

        {this.renderOptionsSection()}
      </div>
    );
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div>
        <Button
          inline
          buttonType="tertiary"
          onClick={() => {
            window.history.back();
          }}
          isActive
          style={{ marginRight: 16 }}
        >
          {this.state.infoId == null ? 'Cancel' : 'Back'}
        </Button>
        {!this.inputsDisabled() && (
          <Button inline buttonType="primary" onClick={() => this.handleSubmit()} isActive={this.validateForm()}>
            Save
          </Button>
        )}
      </div>
    );
  }

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return (
      <SuccessPopup
        text={`Service has been ${this.state.infoId != null ? 'edited' : 'added'}`}
        buttons={[
          {
            type: 'outlined',
            onClick: () => {
              window.history.back();
            },
            text: 'Go to home',
          },
        ]}
      />
    );
  }

  validateRepetitionTime(repetition) {
    if (!_.isUndefined(repetition.StartTime)) {
      return repetition.StartTime.trim() !== '';
    }
    return false;
  }

  validateRepetitionEndTime(repetition) {
    if (!_.isUndefined(repetition.EndTime)) {
      return repetition.EndTime.trim() !== '';
    }
    return false;
  }

  addRepetition() {
    const newReps = [...this.state.Times];
    const id = _.isEmpty(this.state.Times) ? 0 : _.maxBy(this.state.Times, 'Id').Id + 1;

    newReps.push({
      Id: id,
      Day: null,
      StartTime: '9:00am',
      EndTime: '5:00pm',
    });
    this.setState({
      Times: newReps,
    });
  }

  removeRepetition(rep, e) {
    e.stopPropagation();
    rep.Removed = true;
    const newReps = [...this.state.Times];
    const newState = { Times: newReps };

    this.setState(newState);
  }

  renderTimes() {
    let source = this.state.Times;
    source = _.filter(source, (ev) => {
      return !ev.Removed;
    });
    return _.map(source, (repetition, index) => {
      if (repetition.Removed) {
        return null;
      }
      return (
        <div key={repetition.Id} className="flex flex-row flex-center marginBottom-16">
          <DropdownInput
            id={`repitionDay${repetition.Id}`}
            placeholder="Category"
            ignoreValue="Category"
            value={this.getSelectedRepDay(repetition.Day).Title}
            isRequired
            isValid={() => {
              return this.validateRepDay(repetition);
            }}
            showError={() => {
              return this.state.showWarnings && !this.validateRepDay(repetition);
            }}
            options={this.state.Days}
            onSelect={this.selectRepDay.bind(this, repetition.Id)}
            disabled={this.inputsDisabled()}
            style={{ marginBottom: 0, marginRight: 24 }}
          />
          <GenericInput
            id={`repetitionStartTime${repetition.Id}`}
            type="time"
            value={repetition.StartTime}
            isValid={() => {
              return this.validateRepetitionTime(repetition);
            }}
            showError={() => {
              return this.state.showWarnings && !this.validateRepetitionTime(repetition);
            }}
            style={{ marginBottom: 0 }}
            disabled={this.inputsDisabled()}
            inputComponent={
              <TimePicker selectedTime={repetition.StartTime} selectTime={this.handleRepetitionTimeChange.bind(this, repetition.Id)} />
            }
          />
          <div style={{ paddingLeft: 8, paddingRight: 8 }}>-</div>
          <GenericInput
            id={`repetitionEndTime${repetition.Id}`}
            type="time"
            value={repetition.EndTime}
            isValid={() => {
              return this.validateRepetitionEndTime(repetition);
            }}
            showError={() => {
              return this.state.showWarnings && !this.validateRepetitionEndTime(repetition);
            }}
            style={{ marginBottom: 0 }}
            disabled={this.inputsDisabled()}
            inputComponent={
              <TimePicker selectedTime={repetition.EndTime} selectTime={this.handleRepetitionEndTimeChange.bind(this, repetition.Id)} />
            }
          />

          <div className="removeIcon marginLeft-16" onClick={this.removeRepetition.bind(this, repetition)}>
            <SVGIcon icon="close" colour={COLOUR_DUSK} />
          </div>
        </div>
      );
    });
  }

  renderNotify() {
    return (
      <div className="marginTop-16">
        <Text type="formTitleSmall" className="marginBottom-16">
          Notification
        </Text>
        <div className="marginTop-16">
          <RadioButton
            label="Do you want to send a notification with this service?"
            isActive={this.state.shouldNotify}
            options={[
              { Label: 'Yes', Value: true, onChange: () => this.setState({ shouldNotify: true }) },
              { Label: 'No', Value: false, onChange: () => this.setState({ shouldNotify: false }) },
            ]}
          />
          <div className="genericInput-help" style={{ marginTop: 4 }}>
            This will send an alert to all users who have enabled push notifications.
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <OverlayPage>
        <TextFormatPopup onClose={this.toggleTextFormat.bind(this, false)} isOpen={this.state.textFormatOpen} />
        <OverlayPageContents noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--newPopup">
            {this.renderForm()}
            {this.renderSuccess()}
          </OverlayPageSection>
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderSubmit()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  }
}

const styles = {
  contactContainer: {
    marginBottom: 8,
    padding: '8px 16px',
  },
};

const mapStateToProps = (state) => {
  const { events, facilities } = state.events;
  return {
    events,
    facilities,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { servicesUpdate, addRecentlyCreated })(AddService);
