import { authedFunction } from '../session';
import { getUrl } from './helper';

export const automationActions = {
  getEnvironments: (repo) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('automation', 'getEnvironments', { repo }),
    });
  },
  createLiveEnvironment: (repo, name) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'createLiveEnvironment'),
      data: { repo, name },
    });
  },
  deleteLiveEnvironment: (repo, name) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'deleteLiveEnvironment'),
      data: { repo, name },
    });
  },
  getEnvironmentConfigs: (repo, environment) => {
    return authedFunction({
      method: 'GET',
      url: encodeURI(getUrl('automation', 'getEnvironmentConfigs', { repo, environment })),
    });
  },
  getBranchConfig: (repo, branch) => {
    return authedFunction({
      method: 'GET',
      url: encodeURI(getUrl('automation', 'getBranchConfig', { repo, branch })),
    });
  },
  updateEnvironmentConfigs: (repo, environment, configs) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'updateEnvironmentConfigs'),
      data: { repo, environment, configs },
    });
  },
  startDeployment: (data = { environment: '', repo: '', stack: '', buildId: '', merge: false }) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'startDeployment'),
      data,
    });
  },
  stopDeployment: (repo, buildId, pipelineId = '') => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'stopDeployment'),
      data: { repo, buildId, pipelineId },
    });
  },
  getLatestDeploy: (repo, environment) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('automation', 'getLatestDeploy', { repo, environment }),
    });
  },
  getDeployments: (repo) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('automation', 'getDeployments', { repo }),
    });
  },
  getAwsCoreStacks: (environment) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('automation', 'getAwsStacks', { environment, type: 'core' }),
    });
  },
  getAwsStacks: (environment) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('automation', 'getAwsStacks', { environment }),
    });
  },
  getWhiteLabel: (clientCode, rowId = '') => {
    return authedFunction({
      method: 'GET',
      url: getUrl('automation', 'getWhiteLabel', { clientCode, rowId }),
    });
  },
  updateWhiteLabel: (update) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'updateWhiteLabel'),
      data: { update },
    });
  },
  deleteWhiteLabel: (rowId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'deleteWhiteLabel'),
      data: { rowId },
    });
  },
  getBuildQueue: () => {
    return authedFunction({
      method: 'GET',
      url: getUrl('automation', 'getBuildQueue'),
    });
  },
  createAWSAccount: (rowId, name, email) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'createAWSAccount'),
      data: { rowId, name, email },
    });
  },
  setupAWS: (rowId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'setupAWS'),
      data: { rowId },
    });
  },
  setupWeb: (rowId, action) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performWebTask'),
      data: { rowId, action },
    });
  },
  setupAppIOS: (rowId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performAppTaskIOS'),
      data: { rowId },
    });
  },
  setupAppAndroid: (rowId, googleService) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performAppTaskAndroid'),
      data: { rowId, googleService },
    });
  },
  listExtensions: (repo, branch) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performExtension'),
      data: { action: 'list', repo, branch },
    });
  },
  addExtension: (repo, branch, packageName, version, alias) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performExtension'),
      data: { action: 'add', repo, branch, packageName, version, alias },
    });
  },
  removeExtension: (repo, branch, packageName, alias) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'performExtension'),
      data: { action: 'remove', repo, branch, packageName, alias },
    });
  },
  getEmailSender: (rowId, email) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('automation', 'getEmail/email', { rowId, email }),
    });
  },
  verifyEmailSender: (rowId, email) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'updateEmail/verify'),
      data: { rowId, email },
    });
  },
  getEmailService: (rowId) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('automation', 'getEmail/service', { rowId }),
    });
  },
  requestEmailService: (rowId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('automation', 'updateEmail/request'),
      data: { rowId },
    });
  },
};
