import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';
import { SideNavItem } from './';
import { typeActions } from '../webapi';
import { setAuth } from '../actions';
import { isTheBest, validateAccess, isFeatureEnabled } from '../session';
import { Site } from './Site';
import { featureInfo } from '../helper';
import { extensionReducers } from '../config/features';

class SideNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      types: [],
      width: 0,
      height: 0,
      url: window.location.pathname,
      conciergeOpen: true,
      adminOpen: true,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.getUserTypes();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isUndefined(nextProps.auth.userTypes)) {
      this.setState({ types: nextProps.auth.userTypes });
    }
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  getUserTypes() {
    if (!_.isUndefined(this.props.auth.site)) {
      typeActions
        .getUserTypes(this.props.auth.site)
        .then((res) => {
          this.props.setAuth({ userTypes: res.data });
        })
        .catch((error) => {});
    }
  }

  updateUrlPath() {
    setTimeout(() => {
      this.setState({ url: window.location.pathname });
    }, 100);
  }

  toggleConcierge() {
    this.setState({
      conciergeOpen: !this.state.conciergeOpen,
    });
  }

  toggleAdmin() {
    this.setState({
      adminOpen: !this.state.adminOpen,
    });
  }

  renderDefaultNav() {
    return (
      <div style={styles.contentWrapper}>
        <div style={{ ...styles.contentSection }}>
          <SideNavItem
            Text={'Dashboard'}
            Icon={'dashboard'}
            Link={'/mastermenu'}
            onUrlChange={this.updateUrlPath.bind(this)}
            url={this.state.url}
          />
          <SideNavItem
            Text={'Permissions'}
            Icon={'lock'}
            Link={'/permissions'}
            onUrlChange={this.updateUrlPath.bind(this)}
            url={this.state.url}
            isFontAwesome
          />
          {/* <SideNavItem
            Text={'Welcome Information'}
            Icon={'handshake-o'}
            Link={'/welcome'}
            onUrlChange={this.updateUrlPath.bind(this)}
            url={this.state.url}
            isFontAwesome
          /> */}
        </div>
      </div>
    );
  }

  renderHQNav() {
    return (
      <div style={styles.contentWrapper}>
        <div style={{ ...styles.contentSection }}>
          <SideNavItem
            Text={'Dashboard'}
            Icon={'dashboard'}
            Link={'/mastermenu'}
            onUrlChange={this.updateUrlPath.bind(this)}
            url={this.state.url}
          />
          {validateAccess(this.props.auth.site, 'userManagement', this.props.auth) && (
            <SideNavItem
              Text={'Users'}
              Icon={'people'}
              Link={'/usershub'}
              onUrlChange={this.updateUrlPath.bind(this)}
              url={this.state.url}
            />
          )}
          {validateAccess(this.props.auth.site, 'newsletter', this.props.auth) && _.includes(this.props.auth.features, 'news') && (
            <SideNavItem Text={'News'} Icon={'news'} Link={'/news'} onUrlChange={this.updateUrlPath.bind(this)} url={this.state.url} />
          )}
          {validateAccess(this.props.auth.site, 'polls', this.props.auth) && _.includes(this.props.auth.features, 'surveys') && (
            <SideNavItem
              Text={'Surveys'}
              Icon={'pie-chart'}
              isFontAwesome
              Link={'/polls'}
              onUrlChange={this.updateUrlPath.bind(this)}
              url={this.state.url}
            />
          )}
          {validateAccess(this.props.auth.site, 'services', this.props.auth) && _.includes(this.props.auth.features, 'services') && (
            <SideNavItem
              Text={'Services'}
              Icon={'stethoscope'}
              isFontAwesome
              Link={'/services'}
              onUrlChange={this.updateUrlPath.bind(this)}
              url={this.state.url}
            />
          )}
          {isTheBest(this.props.auth, false) && (
            <SideNavItem
              Text={'Permissions'}
              Icon={'lock'}
              Link={'/permissions'}
              onUrlChange={this.updateUrlPath.bind(this)}
              url={this.state.url}
              isFontAwesome
            />
          )}
          {isTheBest(this.props.auth, true) && (
            <SideNavItem
              Text={'Automation'}
              Icon={'bolt'}
              Link={'/automationHub'}
              onUrlChange={this.updateUrlPath.bind(this)}
              url={this.state.url}
              isFontAwesome
            />
          )}
        </div>
      </div>
    );
  }

  renderSideNavItems() {
    const { url } = this.state;
    const { auth } = this.props;
    const ordered = _.orderBy(featureInfo, 'order');
    return (
      <div style={{ ...styles.contentSection }}>
        {ordered.map((item) => {
          const evaluteVisibility = (exp) => {
            if (!exp) return true;
            switch (exp.type) {
              case 'and':
                return exp.exps.every((x) => evaluteVisibility(x));
              case 'or':
                return exp.exps.some((x) => evaluteVisibility(x));
              case 'feature':
                return isFeatureEnabled(auth.features, exp.value);
              case 'permission':
                return validateAccess(auth.site, exp.value, auth);
              case 'state':
                return this.state[exp.value];
              case 'props':
                return this.props[exp.value];
              case 'master':
                return isTheBest(auth, exp.value);
              default:
                throw new Error(`Invalid expression type "${exp.type}" for ${item.text}`);
            }
          };
          if (!evaluteVisibility(item.visibleExps)) return null;

          const getCount = (keys, props = null, index = 0) => {
            if (!keys || keys.length === 0) return null;
            const currentProp = props ? props[keys[index]] : this.props[keys[index]];
            if (keys.length > ++index) return getCount(keys, currentProp, index);
            if (typeof currentProp !== 'number') throw new Error(`countProps does not evaluate to a number for ${item.text}`);
            return currentProp;
          };

          return (
            <SideNavItem
              key={item.key}
              Text={item.text}
              Icon={item.icon}
              isFontAwesome={item.isFontAwesome}
              Link={item.url}
              onUrlChange={this.updateUrlPath.bind(this)}
              url={url}
              count={getCount(item.countProps)}
            />
          );
        })}
      </div>
    );
  }

  renderSideNav() {
    if (this.props.auth.site === 'default') return this.renderDefaultNav();
    if (this.props.auth.site === 'hq') return this.renderHQNav();

    return (
      <div className="sideNav_section">
        <div className="sideNav_section_topDivider"></div>
        {this.renderSideNavItems()}
      </div>
    );
  }

  render() {
    const settingsShown = _.some(['maintenanceTypes', 'feedback', 'terms', 'activateDevices'], (s) => {
      if (s === 'activateDevices') {
        return validateAccess(this.props.auth.site, s, this.props.auth);
      }
      return validateAccess(this.props.auth.site, s, this.props.auth) && isFeatureEnabled(this.props.auth.features, s);
    });
    return (
      <div
        id="sideNavWrapper"
        style={styles.sideNavWrapper}
        className={`sideNav${this.props.condensedSidebar ? ' sideNav-condensed' : ''}`}
      >
        <div className="titleWrapper">
          <span className="fontLight">COMMUNITY</span>
          <span className="fontHeavy">MANAGER</span>
        </div>
        <Site />

        {settingsShown && (
          <SideNavItem
            Text={'Settings'}
            Icon={'settings'}
            Link={'/settings'}
            onUrlChange={this.updateUrlPath.bind(this)}
            url={this.state.url}
            className="sideNav-item-condensed sideNav-item-settings"
          />
        )}
        {this.renderSideNav()}
        <div className="sideNav_sliver"></div>
      </div>
    );
  }
}

const styles = {
  contentSection: {
    paddingTop: 8,
    paddingBottom: 8,
  },
};

const mapStateToProps = (state) => {
  const { auth, events, nav } = state;
  const props = {
    auth,
    eventSubmissions: events.submissions,
    paymentEnabled: auth.user && auth.user.paymentInfo && auth.user.paymentInfo.enabled,
    condensedSidebar: nav.condensedSidebar,
  };
  if (extensionReducers) {
    const reducerKeys = Object.keys(extensionReducers);
    reducerKeys.forEach((key) => {
      props[key] = state[key];
    });
  }
  return props;
};

let exportObj = connect(mapStateToProps, { setAuth })(withRouter(SideNav));

export { exportObj as SideNav };
