import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import StepProgressBar from 'react-step-progress';
import tinycolor from 'tinycolor2';
import 'react-step-progress/dist/index.css';
import _ from 'lodash';
import { connect } from 'react-redux';
import { GenericInput, Button } from '../../components';
import { isTheBest, getApiError } from '../../session';
import { automationActions } from '../../webapi';
import { whiteLabelLoaded, whiteLabelAdded, whiteLabelReset } from '../../actions';
import { automationConfig } from '../../config';
import {
  renderTitle,
  renderDescription,
  renderOpenLinkStep,
  renderTextStep,
  renderImageStep,
  renderCopyToClipboardText,
  renderEmbed,
} from './helper';
import { pctBtwnColours } from '../../js';

const INTERVAL_ACCOUNT_STATUS = 5000;
const STEP_WHITE_LABEL = '1';
const STEP_EMAIL_ACCOUNT = '2';
const STEP_AWS_ACCOUNT = '3';
const STEP_ADMIN_USER = '4';
const STEP_EMAIL_SENDER = '5A';
const STEP_EMAIL_SERVICE = '5B';

class AWSAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      message: {},
      whiteLabelInfo: props.activeWhiteLabel,
    };
    this.steps = [
      { label: 'White Label Details', name: STEP_WHITE_LABEL, render: this.renderStep1, validator: this.canCreateWhiteLabel },
      { label: 'Confirm Email Account', name: STEP_EMAIL_ACCOUNT, render: this.renderStep2, validator: this.canCreateAWSAccount },
      { label: 'Create AWS Account', name: STEP_AWS_ACCOUNT, render: this.renderStep3, validator: this.hasAWSAccountCreated },
      { label: 'Set up Admin User', name: STEP_ADMIN_USER, render: this.renderStep4, validator: this.hasAccessKey },
      { label: 'Set up Email Service', name: STEP_EMAIL_SENDER, render: this.renderStep5, validator: this.hasEmailService },
    ];
    this.checkAWSAccountStatusHandle = null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps', nextProps.activeWhiteLabel);
    if (!_.isEqual(this.props.activeWhiteLabel, nextProps.activeWhiteLabel)) {
      const { message } = this.state;
      if (nextProps.activeWhiteLabel.AWSAccountError) message[STEP_AWS_ACCOUNT] = nextProps.activeWhiteLabel.AWSAccountError;
      this.setState({ whiteLabelInfo: nextProps.activeWhiteLabel, message });
    }
  }

  // TODO: Enable for testing
  // componentDidMount() {
  //   const whiteLabelInfo = {
  //     RowId: '',
  //     ClientCode: 'domaintransfer',
  //     ClientName: '',
  //     Section: 0,
  //     Step: 0,
  //   };
  //   this.setState({ whiteLabelInfo }, this.onLoadWhiteLabel);
  // }

  componentWillUnmount() {
    clearInterval(this.checkAWSAccountStatusHandle);
  }

  isClientCodeValid = () => {
    const { whiteLabelInfo } = this.state;
    return whiteLabelInfo && !_.isEmpty(whiteLabelInfo.ClientCode) && !whiteLabelInfo.ClientCode.startsWith('[');
  };

  isClientNameValid = () => {
    const { whiteLabelInfo } = this.state;
    return whiteLabelInfo && !_.isEmpty(whiteLabelInfo.ClientName) && !whiteLabelInfo.ClientName.startsWith('[');
  };

  isBrandingColourValid = (code) => {
    return !_.isEmpty(code) && code.startsWith('#');
  };

  isImageUrlValid = (url) => {
    return !_.isEmpty(url) && url.startsWith('http');
  };

  isClientEmailValid = () => {
    const { whiteLabelInfo } = this.state;
    return whiteLabelInfo && !_.isEmpty(whiteLabelInfo.ClientEmail);
  };

  canCreateWhiteLabel = () => {
    const { loading } = this.state;
    return this.isClientCodeValid() && this.isClientNameValid() && !loading;
  };

  canCreateAWSAccount = () => {
    return this.canCreateWhiteLabel() && this.isClientEmailValid();
  };

  canCheckAWSAccountStatus = () => {
    const { whiteLabelInfo } = this.state;
    return this.canCreateWhiteLabel() && whiteLabelInfo.AWSAccountRequestId;
  };

  getDefaultClientEmail = () => {
    const { whiteLabelInfo } = this.state;
    return automationConfig.awsAccountEmail.replace('@', `+${whiteLabelInfo.ClientCode}@`);
  };

  hasAWSAccountCreated = () => {
    const { whiteLabelInfo } = this.state;
    return whiteLabelInfo && whiteLabelInfo.AWSAccountId;
  };

  hasAWSAccountError = () => {
    const { whiteLabelInfo } = this.state;
    return whiteLabelInfo && whiteLabelInfo.AWSAccountError;
  };

  hasAccessKey = () => {
    const { whiteLabelInfo } = this.state;
    return whiteLabelInfo && !_.isEmpty(whiteLabelInfo.AWSAccessKey) && !_.isEmpty(whiteLabelInfo.AWSSecretKey);
  };

  hasEmailService = () => {
    const { whiteLabelInfo } = this.state;
    return whiteLabelInfo && !_.isEmpty(whiteLabelInfo.EmailSender) && !_.isEmpty(whiteLabelInfo.EmailServiceCaseId);
  };

  checkAWSAccountStatus = () => {
    if (!this.canCheckAWSAccountStatus()) return;
    const { whiteLabelInfo, message } = this.state;

    message[STEP_AWS_ACCOUNT] = 'Checking AWS account status...';
    this.setState({ loading: true, message }, async () => {
      try {
        const { data } = await automationActions.createAWSAccount(whiteLabelInfo.RowId);
        this.props.whiteLabelLoaded(data);
        message[STEP_AWS_ACCOUNT] = data.AWSAccountError ? `Account creation failed - ${data.AWSAccountError}` : '';
        this.setState({ loading: false, message });
      } catch (error) {
        message[STEP_AWS_ACCOUNT] = getApiError(error).message;
        this.setState({ loading: false, message });
      }
    });
  };

  pollCheckAWSAccountStatus = () => {
    this.checkAWSAccountStatus();
    this.checkAWSAccountStatusHandle = setInterval(() => {
      if (this.hasAWSAccountCreated() || this.hasAWSAccountError()) {
        clearInterval(this.checkAWSAccountStatusHandle);
      } else {
        this.checkAWSAccountStatus();
      }
    }, INTERVAL_ACCOUNT_STATUS);
  };

  onChangeClientCode = (event) => {
    const { whiteLabelInfo } = this.state;
    whiteLabelInfo.ClientCode = event.target.value.replace(' ', '-');
    this.setState({ whiteLabelInfo });
  };

  onChangeText = (event, fieldName) => {
    const { whiteLabelInfo } = this.state;
    whiteLabelInfo[fieldName] = event.target.value;
    this.setState({ whiteLabelInfo });
  };

  onBrandingColourMainEntered = () => {
    try {
      const { whiteLabelInfo } = this.state;
      const newInfo = { ...whiteLabelInfo };
      let changed = false;
      if (!whiteLabelInfo.BrandingColourDark) {
        newInfo.BrandingColourDark = '#' + tinycolor(pctBtwnColours(whiteLabelInfo.BrandingColourMain, '#000', 0.8)).toHex();
        changed = true;
      }
      if (!whiteLabelInfo.BrandingColourLight) {
        newInfo.BrandingColourLight = '#' + tinycolor(pctBtwnColours('#fff', whiteLabelInfo.BrandingColourMain, 0.6)).toHex();
        changed = true;
      }
      if (changed) this.setState({ whiteLabelInfo: newInfo });
    } catch (error) {
      // Ignore
    }
  };

  onLoadWhiteLabel = () => {
    const { whiteLabelInfo, message } = this.state;
    if (!whiteLabelInfo.ClientCode) {
      this.props.whiteLabelReset();
      message[STEP_WHITE_LABEL] = '';
      this.setState({ loading: false, message: '' });
      return;
    }

    message[STEP_WHITE_LABEL] = `Checking ${whiteLabelInfo.ClientCode} details...`;
    this.setState({ loading: true, message }, async () => {
      try {
        const { data } = await automationActions.getWhiteLabel(whiteLabelInfo.ClientCode);
        if (data) {
          this.props.whiteLabelLoaded(data);
        } else {
          this.props.whiteLabelAdded(whiteLabelInfo.ClientCode);
        }
        message[STEP_WHITE_LABEL] = data ? `Details for ${data.ClientName} has been loaded` : '';
        this.setState({ loading: false, message });
      } catch (error) {
        message[STEP_WHITE_LABEL] = getApiError(error).message;
        this.setState({ loading: false, message });
      }
    });
  };

  onVerifyEmailSender = () => {
    const { whiteLabelInfo, message } = this.state;
    if (!whiteLabelInfo.EmailSender) return;

    message[STEP_EMAIL_SENDER] = `Checking ${whiteLabelInfo.EmailSender}...`;
    this.setState({ loading: true, message }, async () => {
      try {
        const { data: emailSenderInfo } = await automationActions.verifyEmailSender(whiteLabelInfo.RowId, whiteLabelInfo.EmailSender);
        // console.log('onVerifyEmailSender', emailSenderInfo);
        whiteLabelInfo.EmailSenderVerified = emailSenderInfo.VerifiedForSendingStatus;
        const { data } = await automationActions.updateWhiteLabel(whiteLabelInfo);
        this.props.whiteLabelLoaded(data);
        message[STEP_EMAIL_SENDER] = `Sender email ${
          emailSenderInfo.VerifiedForSendingStatus ? 'verified' : 'not yet verified, please try again later'
        }`;
        this.setState({ loading: false, message });
      } catch (error) {
        message[STEP_EMAIL_SENDER] = getApiError(error).message;
        this.setState({ loading: false, message });
      }
    });
  };

  onRequestEmailService = () => {
    const { whiteLabelInfo, message } = this.state;

    message[STEP_EMAIL_SERVICE] = 'Checking email service request...';
    this.setState({ loading: true, message }, async () => {
      try {
        const { data: emailServiceInfo } = await automationActions.requestEmailService(whiteLabelInfo.RowId);
        // console.log('onRequestEmailService', emailServiceInfo);
        whiteLabelInfo.EmailServiceCaseId = emailServiceInfo.CaseId;
        whiteLabelInfo.EmailServiceCaseUrl = emailServiceInfo.CaseUrl;
        whiteLabelInfo.EmailServiceStatus = emailServiceInfo.Status;
        whiteLabelInfo.EmailServiceEnabled = emailServiceInfo.ProductionAccessEnabled;
        whiteLabelInfo.EmailServiceFollowUpText = emailServiceInfo.FollowUpText;
        const { data } = await automationActions.updateWhiteLabel(whiteLabelInfo);
        this.props.whiteLabelLoaded(data);
        message[STEP_EMAIL_SERVICE] = `Email service ${
          emailServiceInfo.ProductionAccessEnabled ? 'approved' : 'not yet approved, please try again later'
        }`;
        this.setState({ loading: false, message });
      } catch (error) {
        message[STEP_EMAIL_SERVICE] = getApiError(error).message;
        this.setState({ loading: false, message });
      }
    });
  };

  onSaveWhiteLabel = (step) => {
    const { whiteLabelInfo, message } = this.state;

    message[step] = 'Saving information...';
    this.setState({ loading: true, message }, async () => {
      try {
        if (!whiteLabelInfo.ClientEmail) whiteLabelInfo.ClientEmail = this.getDefaultClientEmail();
        const { data } = await automationActions.updateWhiteLabel(whiteLabelInfo);
        this.props.whiteLabelLoaded(data);
        message[step] = data ? 'Saved successfully' : '';
        this.setState({ loading: false, message });
      } catch (error) {
        message[step] = getApiError(error).message;
        this.setState({ loading: false, message });
      }
    });
  };

  onDeleteWhiteLabel = () => {
    const { whiteLabelInfo, message } = this.state;
    if (!window.confirm(`Are you sure you want to delete ${whiteLabelInfo.ClientName}?`)) return;

    message[STEP_WHITE_LABEL] = `Deleting white label for ${whiteLabelInfo.ClientName}...`;
    this.setState({ loading: true, message }, async () => {
      try {
        await automationActions.deleteWhiteLabel(whiteLabelInfo.RowId);
        this.props.whiteLabelReset();
        message[STEP_WHITE_LABEL] = `Details for ${whiteLabelInfo.ClientName} has been deleted`;
        this.setState({ loading: false, message });
      } catch (error) {
        message[STEP_WHITE_LABEL] = getApiError(error).message;
        this.setState({ loading: false, message });
      }
    });
  };

  onCreateAWSAccount = () => {
    if (!this.canCreateAWSAccount()) return;

    const { whiteLabelInfo, message } = this.state;
    message[STEP_AWS_ACCOUNT] = `Requesting AWS account for ${whiteLabelInfo.ClientName}...`;
    this.setState({ loading: true, message }, async () => {
      try {
        const { data } = await automationActions.createAWSAccount(
          whiteLabelInfo.RowId,
          whiteLabelInfo.ClientName,
          whiteLabelInfo.ClientEmail,
        );
        this.props.whiteLabelLoaded(data);
        message[STEP_AWS_ACCOUNT] = 'AWS account created successfully';
        this.setState({ loading: false, message }, this.pollCheckAWSAccountStatus);
      } catch (error) {
        message[STEP_AWS_ACCOUNT] = getApiError(error).message;
        this.setState({ loading: false, message });
      }
    });
  };

  onMoveStep = (stepIndex) => {
    if (this.props.onMoveStep) this.props.onMoveStep(stepIndex);
  };

  renderClientCodeInput = () => {
    const { whiteLabelInfo } = this.state;
    return (
      <GenericInput
        className="marginTop-10"
        id="clientCode"
        style={{ width: 300 }}
        type="text"
        placeholder="e.g. botanica"
        isValid={this.isClientCodeValid}
        value={whiteLabelInfo && !whiteLabelInfo.ClientCode.startsWith('[') ? whiteLabelInfo.ClientCode : ''}
        onChange={this.onChangeClientCode}
        onBlur={this.onLoadWhiteLabel}
        isRequired
      />
    );
  };

  renderClientNameInput = () => {
    const { whiteLabelInfo, loading } = this.state;
    return (
      <GenericInput
        className="marginTop-10"
        id="clientName"
        style={{ width: 300 }}
        type="text"
        placeholder="e.g. Botanica Lifestyle"
        isValid={this.isClientNameValid}
        value={whiteLabelInfo && !whiteLabelInfo.ClientName.startsWith('[') ? whiteLabelInfo.ClientName : ''}
        onChange={(e) => this.onChangeText(e, 'ClientName')}
        disabled={loading}
        isRequired
      />
    );
  };

  renderBrandingColourInput = (key, placeHolder, onBlur = null) => {
    const { whiteLabelInfo } = this.state;
    const value = whiteLabelInfo ? whiteLabelInfo[key] : '';
    return (
      <GenericInput
        id={key}
        style={{ width: 70, marginBottom: 0 }}
        type="text"
        placeholder={placeHolder}
        isValid={() => this.isBrandingColourValid(value)}
        value={value}
        onChange={(e) => this.onChangeText(e, key)}
        onBlur={onBlur}
      />
    );
  };

  renderImageUrlInput = (key) => {
    const { whiteLabelInfo } = this.state;
    const value = whiteLabelInfo ? whiteLabelInfo[key] : '';
    return (
      <GenericInput
        id={key}
        style={{ width: 500, marginBottom: 0 }}
        type="text"
        placeholder="https://..."
        isValid={() => this.isImageUrlValid(value)}
        value={value}
        onChange={(e) => this.onChangeText(e, key)}
      />
    );
  };

  renderCreateWhiteLabel = () => {
    const { message, whiteLabelInfo } = this.state;
    const isValid = this.canCreateWhiteLabel();
    return (
      <div className="flex flex-row flex-center marginTop-10">
        <Button
          style={{ width: 90 }}
          className="marginRight-16"
          inline
          buttonType="primary"
          onClick={() => this.onSaveWhiteLabel(STEP_WHITE_LABEL)}
          isActive={isValid}
        >
          Save
        </Button>
        {whiteLabelInfo && whiteLabelInfo.RowId ? (
          <Button
            style={{ width: 90 }}
            className="marginRight-16"
            inline
            buttonType="primary"
            onClick={this.onDeleteWhiteLabel}
            isActive={isValid}
          >
            Delete
          </Button>
        ) : null}
        <div>{message[STEP_WHITE_LABEL]}</div>
      </div>
    );
  };

  renderCreateAWSAccount = () => {
    const { message, whiteLabelInfo } = this.state;
    const requested = !_.isEmpty(whiteLabelInfo.AWSAccountRequestId);
    const exists = !_.isEmpty(whiteLabelInfo.AWSAccountId);
    const isValid = this.canCreateAWSAccount();
    return (
      <div className="marginTop-10">
        <div>
          {`Account Id: ${whiteLabelInfo.AWSAccountId || '[Not Ready]'}`}
          {exists ? <FontAwesome className="marginLeft-10 text-teal" name={'check'} /> : null}
        </div>
        <div className="flex flex-row flex-center marginTop-10">
          {requested ? (
            <Button style={{ width: 150 }} inline buttonType="primary" onClick={this.pollCheckAWSAccountStatus} isActive={isValid}>
              Check Account
            </Button>
          ) : (
            <Button style={{ width: 90 }} inline buttonType="primary" onClick={this.onCreateAWSAccount} isActive={isValid}>
              Create
            </Button>
          )}
          <div className="marginLeft-16">{message[STEP_AWS_ACCOUNT]}</div>
        </div>
      </div>
    );
  };

  renderStep1 = () => {
    return (
      <div>
        {renderTitle('Enter white label details')}
        {renderDescription('In this step, we will enter basic deployment information required to start creating a white label.')}
        <ol>
          {renderTextStep(
            <div>
              Enter community key (client code)
              {this.renderClientCodeInput()}
            </div>,
            { paddingBottom: 0 },
          )}
          {renderTextStep(
            <div className="flex flex-row flex-center">
              <div style={{ width: 350 }}>Enter app name (client name)</div>
              {this.renderClientNameInput()}
            </div>,
            { paddingBottom: 0 },
          )}
          {renderTextStep(
            <div className="flex flex-row flex-center">
              <div style={{ width: 350 }}>Enter branding colour - Main</div>
              {this.renderBrandingColourInput('BrandingColourMain', '#FF6363', this.onBrandingColourMainEntered)}
            </div>,
            { paddingBottom: 0 },
          )}
          {renderTextStep(
            <div className="flex flex-row flex-center">
              <div style={{ width: 350 }}>Enter branding colour - Dark</div>
              {this.renderBrandingColourInput('BrandingColourDark', '#D13636')}
            </div>,
            { paddingBottom: 0 },
          )}
          {renderTextStep(
            <div className="flex flex-row flex-center">
              <div style={{ width: 350 }}>Enter branding colour - Light</div>
              {this.renderBrandingColourInput('BrandingColourLight', '#FCE1E1')}
            </div>,
          )}
          {renderTextStep(
            <div>
              Open{' '}
              <a href={`${window.location.origin}/master/appassets`} target="_blank">
                App Assets
              </a>{' '}
              to upload the logo and generate app assets. Copy the image urls and fill in the following information.
            </div>,
          )}
          {renderTextStep(
            <div className="flex flex-row flex-center">
              <div style={{ width: 350 }}>Enter logo image url</div>
              {this.renderImageUrlInput('ImageUrlLogo')}
            </div>,
            { paddingBottom: 0 },
          )}
          {renderTextStep(
            <div className="flex flex-row flex-center">
              <div style={{ width: 350 }}>Enter app icon image url</div>
              {this.renderImageUrlInput('ImageUrlAppIcon')}
            </div>,
            { paddingBottom: 0 },
          )}
          {renderTextStep(
            <div className="flex flex-row flex-center">
              <div style={{ width: 350 }}>Enter app splash image url</div>
              {this.renderImageUrlInput('ImageUrlAppSplash')}
            </div>,
          )}
          {renderTextStep(
            <div>
              Create/Save white label
              {this.renderCreateWhiteLabel()}
            </div>,
          )}
        </ol>
      </div>
    );
  };

  renderStep2 = () => {
    const { whiteLabelInfo, loading, message } = this.state;
    const clientEmail = whiteLabelInfo.ClientEmail || this.getDefaultClientEmail();
    const clientEmailValid = !_.isEmpty(clientEmail);

    return (
      <div>
        {renderTitle('Confirm new email account address')}
        {renderDescription(
          'In this step, we are setting up an email alias to associate to a new AWS account which will host the backend services.',
        )}
        <ol>
          {renderTextStep(
            <div>
              Confirm new email address,
              <GenericInput
                className="marginTop-10"
                id="clientEmail"
                style={{ width: 400 }}
                type="text"
                isValid={() => clientEmailValid}
                value={clientEmail}
                onChange={(e) => this.onChangeText(e, 'ClientEmail')}
                isRequired
              />
              <div className="flex flex-row flex-center">
                <Button
                  inline
                  buttonType="primary"
                  onClick={() => this.onSaveWhiteLabel(STEP_EMAIL_ACCOUNT)}
                  isActive={clientEmailValid && !loading}
                >
                  Confirm
                </Button>
                <div className="marginLeft-16">{message[STEP_EMAIL_ACCOUNT]}</div>
              </div>
            </div>,
          )}
        </ol>
      </div>
    );
  };

  renderStep3 = () => {
    const { activeWhiteLabel } = this.props;
    const actualEmail = activeWhiteLabel.ClientEmail.includes('+') ? automationConfig.awsAccountEmail : activeWhiteLabel.ClientEmail;

    return (
      <div>
        {renderTitle('Create an AWS account')}
        {renderDescription(
          'In this step, we are creating a new AWS account and set the password for new AWS account to access the AWS console.',
        )}
        <ol>
          {renderTextStep(
            <div>
              Create a new AWS account
              {this.renderCreateAWSAccount()}
            </div>,
          )}
          {renderOpenLinkStep('https://console.aws.amazon.com')}
          {renderImageStep(
            <div>
              Perform the following
              <ol className="marginBottom-16">
                <li>
                  Select <b>Root user</b>
                </li>
                <li>
                  Enter <b>Root user email address</b> <b>{renderCopyToClipboardText(activeWhiteLabel.ClientEmail)}</b>
                </li>
              </ol>
              Then click <b>Next</b>
              <br />
              Resolve any Security Checks requested
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Create+Account/3+-+Set+Password/1+-+Sign+In.png',
            '50%',
            '20%',
          )}
          {renderImageStep(
            <div>
              When the screen prompts for password, click <b>Forgot password?</b>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Create+Account/3+-+Set+Password/2+-+Forgot+Password.png',
            '50%',
            '30%',
          )}
          {renderImageStep(
            <div>
              Perform the following
              <ol>
                <li>Type the characters seen in the security check image</li>
                <li>
                  Click <b>Send email</b> button
                </li>
              </ol>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Create+Account/3+-+Set+Password/3+-+Password+Recovery.png',
            '50%',
            '30%',
          )}
          {renderImageStep(
            <div>
              Once the password email has been sent, click <b>Done</b>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Create+Account/3+-+Set+Password/4+-+Password+Email+Sent.png',
            '50%',
            '30%',
          )}
          {renderTextStep('Close the Incognito window')}
          {renderOpenLinkStep('https://www.google.com/gmail')}
          {renderTextStep(
            <div>
              Sign into the email account for <b>{renderCopyToClipboardText(actualEmail)}</b>
            </div>,
          )}
          {renderTextStep(
            <div>
              Open email from <b>Amazon Web Services Password Assistance</b> and click the link to reset the password
            </div>,
          )}
          {renderImageStep(
            <div>
              Enter and confirm new password then click <b>Reset password</b>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Create+Account/3+-+Set+Password/5+-+Reset+Password.png',
            '50%',
            '30%',
          )}
          {renderImageStep(
            'Once the password reset is successful, close the Incognito window',
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Create+Account/3+-+Set+Password/6+-+Reset+Successful.png',
            '50%',
            '30%',
          )}
        </ol>
      </div>
    );
  };

  renderStep4 = () => {
    const { whiteLabelInfo, loading, message } = this.state;
    const awsAccessKeyValid = !_.isEmpty(whiteLabelInfo.AWSAccessKey);
    const awsSecretKeyValid = !_.isEmpty(whiteLabelInfo.AWSSecretKey);
    return (
      <div>
        {renderTitle('Setup Admin Key')}
        {renderDescription(
          'In this step, we create an administrator user in the AWS account and generate an access key/secret key pair to enable programmatic access to the account.',
        )}
        <ol>
          {renderOpenLinkStep('https://console.aws.amazon.com/iam/home?#/users$new?step=details')}
          {renderImageStep(
            <div>
              If not already logged in, log in to the client AWS account created in previou steps
              <ol className="marginBottom-16">
                <li>
                  Select <b>Root user</b>
                </li>
                <li>
                  Enter <b>Root user email address</b> <b>{renderCopyToClipboardText(whiteLabelInfo.ClientEmail)}</b>
                </li>
              </ol>
              Then click <b>Next</b>
              <br />
              Resolve any Security Checks requested
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+AWS/1+-+Set+up+Admin+Key/1+-+Sign+In.png',
            '50%',
            '20%',
          )}
          {renderImageStep(
            <div>
              On the <b>Add user</b> screen,
              <ol className="marginBottom-16">
                <li>
                  Under <b>Set user details</b>, enter the <b>User name</b>
                  <br />
                  <b>{renderCopyToClipboardText(`${whiteLabelInfo.ClientCode}-serverless-admin`)}</b>
                </li>
                <li>
                  Under <b>Select AWS access type</b>, Tick <b>Programmatic access</b> for the <b>Access type</b>
                </li>
              </ol>
              Then click <b>Next: Permission</b>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+AWS/1+-+Set+up+Admin+Key/2+-+Set+User+Details.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              Under <b>Set permissions</b>,
              <ol className="marginBottom-16">
                <li>
                  Select <b>Attach existing policies directly</b>
                </li>
                <li>
                  Under the list of policies, select(check) <b>AdministratorAccess</b>
                </li>
              </ol>
              Then click <b>Next: Tags</b>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+AWS/1+-+Set+up+Admin+Key/3+-+Set+Permissions.png',
            '40%',
            '60%',
          )}
          {renderImageStep(
            <div>
              On the <b>Add tags (optional)</b> section, no action is required. Click <b>Next: Review</b>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+AWS/1+-+Set+up+Admin+Key/4+-+Add+Tags.png',
            '60%',
            '30%',
          )}
          {renderTextStep(
            <div>
              Review the details then click <b>Create user</b> button
            </div>,
          )}
          {renderImageStep(
            <div>
              Once the admin user has been created successfully,
              <ol className="marginBottom-16">
                <li>
                  Select and copy <b>Access key ID</b> and paste below
                  <GenericInput
                    className="marginTop-10"
                    id="awsAccessKey"
                    style={{ width: 300 }}
                    type="text"
                    placeholder="e.g. AKIAXOX4R6FHFTZQG6M3"
                    isValid={() => awsAccessKeyValid}
                    value={whiteLabelInfo.AWSAccessKey}
                    onChange={(e) => this.onChangeText(e, 'AWSAccessKey')}
                    isRequired
                  />
                </li>
                <li>
                  Click <b>Show</b> then select and copy <b>Secret access key</b>
                  <GenericInput
                    className="marginTop-10"
                    id="clientCode"
                    style={{ width: 300 }}
                    type="text"
                    placeholder="e.g. qWBawnKs7hppxzS247Lp7vnXtFDDTrQnx+MhmDDe"
                    isValid={() => awsSecretKeyValid}
                    value={whiteLabelInfo.AWSSecretKey}
                    onChange={(e) => this.onChangeText(e, 'AWSSecretKey')}
                    isRequired
                  />
                </li>
              </ol>
              <div className="flex flex-row flex-center">
                <Button
                  inline
                  buttonType="primary"
                  onClick={() => this.onSaveWhiteLabel(STEP_ADMIN_USER)}
                  isActive={awsAccessKeyValid && awsSecretKeyValid && !loading}
                >
                  Save
                </Button>
                <div className="marginLeft-16">{message[STEP_ADMIN_USER]}</div>
              </div>
              <p className="fontHeavy text-plussRed marginTop-16">
                This is important as you won’t be able to view the secret after leaving this page
              </p>
            </div>,
            'https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/whitelabel/Set+up+AWS/1+-+Set+up+Admin+Key/6+-+Success.png',
            '40%',
            '60%',
          )}
          {renderTextStep('Close the Incognito window')}
        </ol>
      </div>
    );
  };

  renderStep5 = () => {
    const { whiteLabelInfo, loading, message } = this.state;
    const {
      EmailSender,
      EmailServiceCaseId,
      EmailServiceCaseUrl,
      EmailSenderVerified,
      EmailServiceStatus,
      EmailServiceEnabled,
      EmailServiceFollowUpText,
      ClientEmail,
    } = whiteLabelInfo;
    const isEmailSenderValid = !_.isEmpty(EmailSender);

    return (
      <div>
        {renderTitle('Set up Email Service')}
        {renderDescription(
          'In this step, we register the email address from which to send email communications from and request AWS to enable production usage of the email service.',
        )}
        <ol>
          {renderTextStep(
            <div>
              Verify sender email address
              <div className="flex flex-row flex-center">
                <GenericInput
                  className="marginTop-10"
                  id="emailSender"
                  style={{ width: 400 }}
                  type="text"
                  isValid={() => isEmailSenderValid}
                  value={EmailSender}
                  onChange={(e) => this.onChangeText(e, 'EmailSender')}
                  isRequired
                />
                {EmailSenderVerified ? <FontAwesome className="marginLeft-10 text-teal" name={'check'} /> : null}
              </div>
              <div className="flex flex-row flex-center">
                <Button inline buttonType="primary" onClick={() => this.onVerifyEmailSender()} isActive={isEmailSenderValid && !loading}>
                  Verify
                </Button>
                <div className="marginLeft-16">{message[STEP_EMAIL_SENDER]}</div>
              </div>
            </div>,
          )}
          {EmailSenderVerified
            ? renderTextStep(
                <div>
                  Request production approval
                  <div className="marginTop-10 flex flex-row flex-center">
                    <div className="marginRight-8">Case Id:</div>
                    {EmailServiceCaseUrl ? (
                      <a href={EmailServiceCaseUrl} target="_blank">
                        {EmailServiceCaseId}
                      </a>
                    ) : (
                      <div>[Not Assigned]</div>
                    )}
                    {EmailServiceEnabled ? <FontAwesome className="marginLeft-10 text-teal" name={'check'} /> : null}
                  </div>
                  <div className="flex flex-row flex-center">
                    <div className="marginRight-8">Status:</div>
                    <div>{EmailServiceStatus}</div>
                  </div>
                  {EmailServiceCaseId && !EmailServiceEnabled ? (
                    <div className="marginTop-10">
                      <div>
                        To provide more information for the declined approval process, right click on the Case Id link and click{' '}
                        <b>Opne Link in Incognito Window</b>
                      </div>
                      <ol className="marginTop-10">
                        <li>
                          If not already logged in, log in to the client AWS account created in previou steps
                          <ol className="marginBottom-16">
                            <li>
                              Select <b>Root user</b>
                            </li>
                            <li>
                              Enter <b>Root user email address</b> <b>{renderCopyToClipboardText(ClientEmail)}</b>
                            </li>
                          </ol>
                          Then click <b>Next</b>
                          <br />
                          Resolve any Security Checks requested
                        </li>
                        <li>
                          Click on the <b>Reply</b> button on the <b>Correspondence</b> section
                        </li>
                        <li>
                          <div>
                            Copy the following text, paste it to the <b>Reply</b> section and click <b>Submit</b> button
                          </div>
                          {renderEmbed(EmailServiceFollowUpText)}
                        </li>
                      </ol>
                    </div>
                  ) : null}
                  <div className="marginTop-16 flex flex-row flex-center">
                    <Button inline buttonType="primary" onClick={() => this.onRequestEmailService()} isActive={!loading}>
                      Request
                    </Button>
                    <div className="marginLeft-16">{message[STEP_EMAIL_SERVICE]}</div>
                  </div>
                </div>,
              )
            : null}
        </ol>
      </div>
    );
  };

  render() {
    if (!isTheBest(this.props.auth, true)) return null;

    const { stepIndex } = this.props;
    const step = stepIndex && stepIndex < this.steps.length ? stepIndex : 0;
    return (
      <div className="flex-1 automation">
        <StepProgressBar
          startingStep={step}
          progressClass="progressBar"
          primaryBtnClass="primaryBtn"
          secondaryBtnClass="secondaryBtn"
          submitBtnName="Done"
          onPrevious={() => this.onMoveStep(step - 1)}
          onNext={() => this.onMoveStep(step + 1)}
          onSubmit={() => (this.props.onDone ? this.props.onDone() : null)}
          steps={this.steps}
        />
        {this.steps[step].render()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, automation } = state;
  return {
    auth,
    activeWhiteLabel: automation.active,
  };
};

export default connect(mapStateToProps, { whiteLabelLoaded, whiteLabelAdded, whiteLabelReset })(AWSAccount);
